// Lista de convenio e medicos que NAO atendem eles
export const filterAgreements = [
    {
        agreement: 1086, // "AMIL",
        doctors: ["Dra. Júlia Tibério",
        "Dra. Thais Oliveira",
        "Dr. Talles Petterson",
        "Dra. Daniela Couto",
        "Dr. Yuri Oliveira",
        "Dra. Raianni Pires", 
        "Dra. Melina Oliveira",
        "Dra. Raissa Lacerda",
        "Dra. Gisele Morato",
        "Dra. Adriana Costa", 
        "Dr. Antônio Márcio Luciano", 
        "Dra. Cristiana Bertin", 
        "Dr. Jadir Macedo", 
        "Dra. Maria Araceli Oliveira", 
        "Dr. Tulio Reis", 
        "Dra. Letícia Cazar",
        "Dra. Marina Albuquerque",
        "Dra. Junia França",
        "Dra. Juliana Rocha",
        "Dr. Fernando Cresta",
        "Dra. Cristina Souza",
        "Dra. Islara Machado",]
    },
    {
        agreement: 11, // "CASSI",
        doctors: ["Dra. Maria Araceli Oliveira",
             "Dr. Tulio Reis",
             "Dra. Adriana Costa"]
    },
    {
        agreement: 1011,  //"BOMBEIROS",
        doctors: ["Dra. Maria Araceli Oliveira"]
    },
    {
        agreement: 175,  //"LIFE EMPRESARIAL",
        doctors: ["Dra. Maria Araceli Oliveira"]
    },
    {
        agreement: 1063, // "PREVEN MED",
        doctors: ["Dra. Maria Araceli Oliveira"]
    },
    {
        agreement: 1061, // "SAUDE SIN PG NO ATO",
        doctors: ["Dra. Maria Araceli Oliveira"]
    },
   
    {
        agreement: 1045, //"SESC PG NO ATO",
        doctors: ["Dra. Maria Araceli Oliveira","Dr. Tulio Reis"]
    },
    {
        agreement: 1010, // "VIDA CARD/ASSEDF",
        doctors: ["Dra. Maria Araceli Oliveira"]
    },
    {
        agreement: 1062, // "ASFUB",
        doctors: ["Dr. Tulio Reis"]
    },
    {
        agreement: 1067, // "SMILE SAUDE",
        doctors: ["Dr. Tulio Reis"]
    },
    
]
